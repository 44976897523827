
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import MarkerClusterer from '@google/markerclusterer';
import uniqid from 'uniqid';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    computed: {},
    props: {
        coords: {
            type: Array,
            required: true
        },
        mapVisible: {
            type: Boolean,
            required: true,
            default: false
        },
        type: {
            type: String,
            required: false,
            default: 'mijn-nacht'
        }
    },
    data() {
        return {
            google: {},
            uniqueId: uniqid()
        };
    },
    methods: {
        initGMaps() {
            const API_KEY = 'AIzaSyBFOfOySfh62uuEAgZ5rP-vS4KlKpv5KGg';
            const CALLBACK_NAME = 'gmapsCallback';

            let initialized = !!window.google;
            let resolveInitPromise;
            let rejectInitPromise;
            const initPromise = new Promise((resolve, reject) => {
                resolveInitPromise = resolve;
                rejectInitPromise = reject;
            });
            if (initialized) return initPromise;

            initialized = true;
            window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

            const script = document.createElement('script');
            script.async = true;
            script.defer = true;
            script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=${CALLBACK_NAME}`;
            script.onerror = rejectInitPromise;
            document.querySelector('head').appendChild(script);

            return initPromise;
        },
        buildMap() {
            const target = document.getElementById('googleMap' + this.uniqueId);
            const options = {
                zoom: 13,
                styles: [
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#e9e9e9'
                            },
                            {
                                lightness: 17
                            }
                        ]
                    },
                    {
                        featureType: 'landscape',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#f5f5f5'
                            },
                            {
                                lightness: 20
                            }
                        ]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.fill',
                        stylers: [
                            {
                                color: '#ffffff'
                            },
                            {
                                lightness: 17
                            }
                        ]
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [
                            {
                                color: '#ffffff'
                            },
                            {
                                lightness: 29
                            },
                            {
                                weight: 0.2
                            }
                        ]
                    },
                    {
                        featureType: 'road.arterial',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#ffffff'
                            },
                            {
                                lightness: 18
                            }
                        ]
                    },
                    {
                        featureType: 'road.local',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#ffffff'
                            },
                            {
                                lightness: 16
                            }
                        ]
                    },
                    {
                        featureType: 'poi',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#f5f5f5'
                            },
                            {
                                lightness: 21
                            }
                        ]
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#dedede'
                            },
                            {
                                lightness: 21
                            }
                        ]
                    },
                    {
                        elementType: 'labels.text.stroke',
                        stylers: [
                            {
                                visibility: 'on'
                            },
                            {
                                color: '#ffffff'
                            },
                            {
                                lightness: 16
                            }
                        ]
                    },
                    {
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                saturation: 36
                            },
                            {
                                color: '#333333'
                            },
                            {
                                lightness: 40
                            }
                        ]
                    },
                    {
                        elementType: 'labels.icon',
                        stylers: [
                            {
                                visibility: 'off'
                            }
                        ]
                    },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#f2f2f2'
                            },
                            {
                                lightness: 19
                            }
                        ]
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry.fill',
                        stylers: [
                            {
                                color: '#fefefe'
                            },
                            {
                                lightness: 20
                            }
                        ]
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry.stroke',
                        stylers: [
                            {
                                color: '#fefefe'
                            },
                            {
                                lightness: 17
                            },
                            {
                                weight: 1.2
                            }
                        ]
                    }
                ]
            };

            const map = new google.maps.Map(target, options);

            const markerClickHandler = marker => {
                map.setZoom(15);
                map.setCenter(marker.getPosition());
            };

            let bounds = new google.maps.LatLngBounds();

            let markerImg = '';
            let markerSizes;
            this.type == 'museum-overview'
                ? (markerImg = 'map_marker2')
                : (markerImg = 'map_marker1');
            this.type == 'museum-overview'
                ? (markerSizes = [28, 36])
                : (markerSizes = [40, 40]);

            let infoWindows = [];

            const markers = this.coords.map((location, i) => {
                var markerIcon = {
                    url: `${window.location.origin}/assets/images/${markerImg}.png`,
                    scaledSize: new google.maps.Size(markerSizes[0], markerSizes[1])
                };

                let markerLabel =
                    this.type == 'museum-overview' ? ' ' : (i + 1).toString();

                infoWindows.push(
                    new google.maps.InfoWindow({
                        content: `
                        <h3>${location.name}</h3>
                        <p>${location.address}</p>
                    `
                    })
                );

                var marker = new google.maps.Marker({
                    map: map,
                    position: location.position,
                    icon: markerIcon,
                    label: {
                        text: markerLabel,
                        color: '#ffffff',
                        fontSize: '20px',
                        fontWeight: 'bold'
                    }
                });
                bounds.extend(
                    new google.maps.LatLng(location.position.lat, location.position.lng)
                );

                marker.addListener('click', function() {
                    markerClickHandler(marker);
                    infoWindows.forEach(el => {
                        el.close();
                    });
                    infoWindows[i].open(map, marker);
                });
                return marker;
            });
            map.setCenter(bounds.getCenter());
            map.setZoom(13);
        }
    },
    async mounted() {
        try {
            const google = await this.initGMaps();
            this.buildMap();

        } catch (error) {
            console.error(error);
        }
    },
    watch: {
        mapVisible(newValue) {
            if(newValue) {
                this.buildMap();
            }
        }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Map extends Vue {}
