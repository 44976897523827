
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapMutations } from 'vuex';

import ArrowSvg from '@svg/Arrow.vue';
import Thumb from '@part/elements/Thumb.vue';
import axios from 'axios';
import Map from '@part/elements/Map.vue';
import TextBlock from '@part/blocks/TextBlock.vue';
/* -------------  ---------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    TextBlock,
    Thumb,
    Map,
    ArrowSvg
  },
  props: ['museumData', 'museumCoords', 'cardsTitle', 'pageData', 'favButton'],
  data() {
    return {
      mapVisible: false,
      reversed: false,
      animating: false
    };
  },

  computed: {},
  methods: {
    ...mapMutations(['startReloadMyNightButton']),
    toggleMap() {
      this.mapVisible = !this.mapVisible;
    },
    reverse() {
      if (!this.museumData) {
        return;
      }
      let animTime = 500;
      this.reversed = !this.reversed;
      this.animating = true;
      setTimeout(() => {
        this.startReloadMyNightButton();
        this.animating = false;
      }, animTime);
      setTimeout(() => {
        this.museumData.data = this.museumData.data.reverse();
      }, animTime / 2);
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class OverviewContainer extends Vue {}
